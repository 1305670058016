// @ts-check
/**
 * @fileoverview redux selectors for Employees
 */

import {createSelector} from "reselect";

const selectEmployeesState = (state) => state.employees;

const selectEmployees = (state) => selectEmployeesState(state).employees;
const selectEmployeeIds = createSelector(selectEmployeesState, (employeeState) => Object.keys(employeeState.employees));
const selectStatusEmployees = (state) => selectEmployeesState(state).statusEmployees;
const selectErrorEmployees = (state) => selectEmployeesState(state).errorEmployees;

export {selectEmployees, selectStatusEmployees, selectErrorEmployees, selectEmployeeIds};
