import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./employees_action_types";

const initialState = {
    employees: [],
    statusEmployees: STATUS.IDLE,
    errorEmployees: null
};

const handleEmployeesRequest = (state) => ({
    ...state,
    statusEmployees: STATUS.PENDING,
    errorEmployees: null
});

const handleEmployeesFailure = (state, {error}) => ({
    ...state,
    statusEmployees: STATUS.REJECTED,
    errorEmployees: error
});

const handleEmployeesSuccess = (state, {payload}) => ({
    ...state,
    statusEmployees: STATUS.RESOLVED,
    errorEmployees: null,
    employees: payload
});

const handlers = {
    [ActionTypes.EMPLOYEES_REQUEST]: handleEmployeesRequest,
    [ActionTypes.EMPLOYEES_SUCCESS]: handleEmployeesSuccess,
    [ActionTypes.EMPLOYEES_FAILURE]: handleEmployeesFailure
};
export default createReducer(initialState, handlers);
