import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        position: "relative",
        overflow: "hidden",
        borderRadius: "2px",
        borderWidth: "1px",
        borderStyle: "solid",
        padding: `${theme.spacing("xs")} ${theme.spacing("m")} ${theme.spacing("xs")} ${theme.spacing("s")} `,
        fontSize: "0.688rem",
        fontWeight: 500,
        lineHeight: "0.75rem",
        height: "1.25rem",
        whiteSpace: "nowrap"
    },
    available: {
        color: theme.palette.primary.main,
        textOverflow: "ellipsis",
        borderColor: "transparent",
        backgroundColor: theme.palette.grey[100]
    },
    unavailable: {
        color: theme.palette.primary.contrastText,
        textOverflow: "ellipsis",
        borderColor: "transparent",
        backgroundColor: theme.palette.error.main,
        paddingRight: theme.spacing("s")
    },
    separator: {
        marginLeft: theme.spacing("xs"),
        marginRight: theme.spacing("xs")
    },
    highlight: {
        position: "absolute",
        top: "0.25rem",
        right: "0.125rem",
        height: "0.75rem",
        width: "0.75rem",
        borderRadius: "0.375rem",
        border: "2px solid #fff"
    }
}));
