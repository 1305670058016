/**
 * @fileoverview redux action types for availability
 */

const ActionTypes = {
    CLEAR_SLOT: "employee_availability/CLEAR_SLOT",

    LOAD_SLOT_REQUEST: "employee_availability/LOAD_SLOT_REQUEST",
    LOAD_SLOT_SUCCESS: "employee_availability/LOAD_SLOT_SUCCESS",
    LOAD_SLOT_FAILURE: "employee_availability/LOAD_SLOT_FAILURE",

    VALIDATE_SLOT_REQUEST: "employee_availability/VALIDATE_SLOT_REQUEST",
    VALIDATE_SLOT_SUCCESS: "employee_availability/VALIDATE_SLOT_SUCCESS",
    VALIDATE_SLOT_FAILURE: "employee_availability/VALIDATE_SLOT_FAILURE",

    SAVE_SLOT_REQUEST: "employee_availability/SAVE_SLOT_REQUEST",
    SAVE_SLOT_SUCCESS: "employee_availability/SAVE_SLOT_SUCCESS",
    SAVE_SLOT_FAILURE: "employee_availability/SAVE_SLOT_FAILURE",

    DELETE_SLOT_REQUEST: "employee_availability/DELETE_SLOT_REQUEST",
    DELETE_SLOT_SUCCESS: "employee_availability/DELETE_SLOT_SUCCESS",
    DELETE_SLOT_FAILURE: "employee_availability/DELETE_SLOT_FAILURE",

    CLEAR_VALIDATE: "employee_availability/CLEAR_VALIDATE",

    SET_COUNT_NEW_SLOTS: "employee_availability/SET_COUNT_NEW_SLOTS"
};

export default ActionTypes;
