/**
 * @fileoverview redux selectors for employee availability
 */

import {createSelector} from "reselect";

const selectEmployeeAvailabilityState = (state) => state.employeeAvailability;

const selectError = (state) => selectEmployeeAvailabilityState(state).error;

const selectStatus = (state) => selectEmployeeAvailabilityState(state).status;

const selectSlots = (state) => selectEmployeeAvailabilityState(state).slots;

const selectConflicts = (state) => selectEmployeeAvailabilityState(state).conflicts;

const selectFlatConflicts = createSelector(selectConflicts, (conflicts) =>
    conflicts
        .reduce((accumulator, {type, existingSlot}) => [...accumulator, {type, start: existingSlot.start, end: existingSlot.end}], [])
        .sort((a, b) => (a.start < b.end ? -1 : 1))
);

const selectCalculatedDates = (state) => selectEmployeeAvailabilityState(state).preCalcDates;

const selectValidateStatus = (state) => selectEmployeeAvailabilityState(state).validateStatus;

const selectValidate = (state, {slotId}) => selectEmployeeAvailabilityState(state)[slotId];

const selectAvailabilityAddedSlotId = (state, {newSlotIndex}) => selectEmployeeAvailabilityState(state).added[newSlotIndex];

const selectCountNewSlots = (state) => selectEmployeeAvailabilityState(state).countNewSlots;

const selectDeletedSlotIds = (state) => selectEmployeeAvailabilityState(state).deleted;

export {
    selectConflicts,
    selectFlatConflicts,
    selectCalculatedDates,
    selectSlots,
    selectError,
    selectStatus,
    selectValidateStatus,
    selectValidate,
    selectAvailabilityAddedSlotId,
    selectCountNewSlots,
    selectDeletedSlotIds
};
