/**
 * @fileoverview redux reducer for availabilites
 */

import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./employees_availabilities_action_types";

const initialState = {
    fromDate: null,
    toDate: null,
    employees: {}, // {practitionerId: {pracroleId: {absences, availabilities}}}
    status: STATUS.IDLE,
    error: null,
    filteredPractitionerIds: [],
    availabilityMode: null
};

/**
 * remove slot in an array of employees
 * @param {object} employees    the employees data
 * @param {string} empId        the employee id of the employee to change
 * @param {string} slotId       the slot to remove
 * @return {Array} a new array with the modified employee
 */
const removeSlot = (employees, empId, slotId) => {
    Object.keys(employees[empId]).forEach((pracRoleId) => {
        employees[empId][pracRoleId].availabilities = employees[empId][pracRoleId].availabilities.filter((item) => item.slotId !== slotId);
        employees[empId][pracRoleId].absences = employees[empId][pracRoleId].absences.filter((item) => item.slotId !== slotId);
    });
    return employees;
};

const updateAvailabilities = (employees, empId, availabilities, absences) =>
    employees.map((employee) => {
        if (employee.empId !== empId) {
            return employee;
        }

        return {
            ...employee,
            availabilities,
            absences
        };
    });

const handleLoadRequest = (state, {fromDate, toDate}) => ({
    ...state,
    status: STATUS.PENDING,
    error: null,
    fromDate,
    toDate
});

const handleLoadFailure = (state, {error}) => ({
    ...state,
    status: STATUS.REJECTED,
    error
});

const handleLoadSuccess = (state, {payload}) => ({
    ...state,
    status: STATUS.RESOLVED,
    error: null,
    employees: payload
});

const handleUpdateSuccess = (state, {empId, availability, absences}) => ({
    ...state,
    employees: updateAvailabilities(state.employees, empId, availability, absences)
});

const handleDeleteSuccess = (state, {empId, slotId}) => ({
    ...state,
    employees: removeSlot(state.employees, empId, slotId)
});

const handleSaveFilteredEmpIds = (state, {practitionerIds}) => ({
    ...state,
    filteredPractitionerIds: [...practitionerIds]
});

const handleSaveAvailabilitySetting = (state, {settings}) => {
    let mode = null;
    if (settings && settings.availabilityMode) {
        mode = settings.availabilityMode;
    }
    return {
        ...state,
        availabilityMode: mode
    };
};
const handlers = {
    [ActionTypes.LOAD_REQUEST]: handleLoadRequest,
    [ActionTypes.LOAD_SUCCESS]: handleLoadSuccess,
    [ActionTypes.LOAD_FAILURE]: handleLoadFailure,

    [ActionTypes.UPDATE_SUCCESS]: handleUpdateSuccess,

    [ActionTypes.DELETE_SUCCESS]: handleDeleteSuccess,

    [ActionTypes.SAVE_FILTERED_EMPLOYEES]: handleSaveFilteredEmpIds,

    [ActionTypes.FETCH_SETTING]: handleSaveAvailabilitySetting
};

export default createReducer(initialState, handlers);
