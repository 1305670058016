// @ts-check
import {array, bool, func, number, object, string} from "prop-types";
import React, {useMemo} from "react";
import {shallowEqual, useSelector} from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import {VariableSizeList} from "react-window";

import {selectCurrentUserPractitionerId} from "../../../../redux/app_selectors";
import {useResetCache} from "../../../timeslot_dialog/hooks/useResetCache";
import useStyles from "../week_table.styles";
import {AvailabilitiesRow} from "./availabilities_row";
import {getItemsSize} from "./helpers";

/**
 *
 * @param {Object} props
 * @param {Function} props.onAdd
 * @param {Array<EmployeeData>} props.dataOrganized
 * @param {string} props.timezone
 * @param {boolean} props.isPending
 * @param {Array<DateTimeType>} props.dateRange
 * @param {boolean} props.showWeekend
 * @return {React.ReactElement}
 */
export const AvailabilitiesList = ({onAdd, dataOrganized, timezone, isPending, dateRange, showWeekend}) => {
    const practitionerIdUser = useSelector(selectCurrentUserPractitionerId, shallowEqual);
    const {classes} = useStyles();
    const ref = useResetCache(dataOrganized);

    const currentEmployee = useMemo(
        () => dataOrganized.find(({practitionerId}) => practitionerIdUser === practitionerId),
        [practitionerIdUser, dataOrganized]
    );
    const hasPractitionerId = Boolean(currentEmployee);
    const handleAdd = (practitionerId, date) => (variant, periods) => onAdd({empId: practitionerId, date, variant, periods});

    const RenderRow = ({index, style}) => (
        <AvailabilitiesRow
            dataOrganized={dataOrganized}
            dateRange={dateRange}
            handleAdd={handleAdd}
            hasPractitionerId={hasPractitionerId}
            index={index}
            isPending={isPending}
            showWeekend={showWeekend}
            style={style}
            timezone={timezone}
        />
    );
    RenderRow.propTypes = {
        index: number.isRequired,
        style: object.isRequired
    };
    return (
        <AutoSizer>
            {({height, width}) => (
                <VariableSizeList
                    className={classes.noScrollbar}
                    height={height}
                    itemCount={dataOrganized?.length}
                    itemData={dataOrganized}
                    itemSize={(index) => getItemsSize({index, dataOrganized, timezone, hasPractitionerId})}
                    ref={ref}
                    width={width}
                >
                    {RenderRow}
                </VariableSizeList>
            )}
        </AutoSizer>
    );
};
AvailabilitiesList.propTypes = {
    dataOrganized: array.isRequired,
    isPending: bool,
    timezone: string.isRequired,
    onAdd: func.isRequired,
    dateRange: array.isRequired,
    showWeekend: bool
};
