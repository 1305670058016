import {Close, Search} from "@mui/icons-material";
import {FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import PropTypes from "prop-types";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import useStyles from "./employee_select.styles";

const EmployeeSelect = ({className, disabled, label, options, placeholder, value, renderValue, onChange}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const [open, setOpen] = useState(false);
    const [filterText, setFilterText] = useState("");

    /**
     * Set input text in filterText and value of input
     * @param {object} e
     */
    const handleFilterText = (e) => {
        setFilterText(e.target.value);
        inputRef.current.value = e.target.value;
    };

    /**
     * Reset selection
     */
    const handleResetClick = () => {
        onChange("");
    };

    /**
     * Handler for change selected item
     * Only set when menu items except text field (first item) are clicked
     * @param {object} e
     * @param {object} child - child.key
     */
    const handleChange = (e, child) => {
        if (child.key !== ".0") {
            onChange(e.target.value);
        }
    };

    /**
     * Handler to keep focus on text field if the text field is clicked
     * By default, the focus is set on the menu item which matches with the entered key
     * @param {object} e
     */
    const handleKeyPress = (e) => {
        if (e.key) {
            inputRef.current.focus();
        }
    };

    /**
     * Handler to reset filterText
     * Since TextField is uncontrolled, both state and input value are set.
     * @param {object} e - event object
     */
    const handleResetFilterText = (e) => {
        e.stopPropagation();
        setFilterText("");
        inputRef.current.value = "";
    };

    /**
     * stop firing MenuItem event if TextField is clicked/entered
     * @param {object} e - event object
     */
    const stopPropagation = (e) => {
        switch (e.key) {
            case "ArrowDown":
            case "ArrowUp":
                break;
            default:
                e.stopPropagation();
        }
    };

    return (
        <FormControl className={className} variant="standard">
            <InputLabel disabled={disabled} id="employee-select" shrink>
                {label}
            </InputLabel>
            <Select
                disabled={disabled}
                displayEmpty
                inputProps={{
                    className: cx(classes.input, {[classes.inputDisabled]: disabled})
                }}
                labelId="employee-select"
                MenuProps={{
                    classes: {paper: classes.menuWrapper},
                    variant: "menu",
                    onKeyPress: handleKeyPress
                }}
                open={open}
                placeholder={placeholder}
                renderValue={(value) => {
                    if (!value) {
                        return <span className={classes.placeholder}>{placeholder}</span>;
                    }
                    return (
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div>{renderValue(value)}</div>
                            {!disabled && <Close className={classes.closeIcon} id="reset" onClick={handleResetClick} />}
                        </div>
                    );
                }}
                value={value}
                variant="standard"
                onChange={handleChange}
                onClose={(e) => {
                    if (e.target.id !== "searchField" && !e.target.closest("path")) {
                        setOpen(false);
                    }
                }}
                onOpen={(e) => {
                    // If reset icon is clicked, do not open menu.
                    if (e.target.id !== "reset" && !e.target.closest("path")) {
                        setOpen(true);
                    }
                }}
            >
                <TextField
                    className={classes.inputWrapper}
                    // uncontrolled in order to enable reset text
                    defaultValue={filterText}
                    id="searchField"
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color="primary" fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: filterText && (
                            <InputAdornment position="end">
                                <Close
                                    className={classes.closeIcon}
                                    fontSize="small"
                                    style={{cursor: "pointer"}}
                                    onClick={handleResetFilterText}
                                />
                            </InputAdornment>
                        ),
                        disableUnderline: true
                    }}
                    inputRef={inputRef}
                    placeholder={t("App.searchSurgeons")}
                    variant="standard"
                    onChange={handleFilterText}
                    onKeyDown={stopPropagation}
                />
                {options
                    .filter((option) => option.label.toLowerCase().includes(filterText ? filterText.toLowerCase() : ""))
                    .map((option) => (
                        <MenuItem className={classes.menuItem} key={option.value + "-" + option.label} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

EmployeeSelect.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ),
    placeholder: PropTypes.string,
    value: PropTypes.string,
    renderValue: PropTypes.func,
    onChange: PropTypes.func.isRequired
};

export default EmployeeSelect;
