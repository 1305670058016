/**
 * @fileoverview redux actions for availabilities
 */

import {luxonToken} from "../../contexts/dates";
import {authUserFailureAction} from "../../redux/actions/index";
import {format} from "../../utils/luxon_helpers";
import {loadNamesAction} from "../private_data/private_data_actions";
import ActionTypes from "./employees_availabilities_action_types";
import {fetchAllEmployeesAvailabilities, fetchSettingAPI} from "./employees_availabilities_api";
import {selectFromDate, selectToDate} from "./employees_availabilities_selectors";

const loadEmployeesAvailabilitiesRequestAction = (fromDate, toDate) => ({
    type: ActionTypes.LOAD_REQUEST,
    fromDate,
    toDate
});

const loadEmployeesAvailabilitiesSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_SUCCESS,
    payload
});

const loadEmployeesAvailabilitiesFailureAction = (error) => ({
    type: ActionTypes.LOAD_FAILURE,
    error
});

/**
 * load availabilities and absences for employees
 * @param {string} organizationId
 * @param {DateTimeType} fromDate
 * @param {DateTimeType} toDate
 * @return {function}
 */
function loadEmployeesAvailabilitiesAction(organizationId, fromDate = null, toDate = null) {
    return function (dispatch, getState) {
        const from = fromDate || selectFromDate(getState());
        const to = toDate || selectToDate(getState());

        dispatch(loadEmployeesAvailabilitiesRequestAction(from, to));
        const fromDateFormatted = format(from, luxonToken.SYSTEM_DATE.de);
        const toDateFormatted = format(to, luxonToken.SYSTEM_DATE.de);

        fetchAllEmployeesAvailabilities(organizationId, fromDateFormatted, toDateFormatted)
            .then(({data}) => {
                dispatch(loadEmployeesAvailabilitiesSuccessAction(data.data));

                // load names
                dispatch(loadNamesAction(organizationId, "practitioner", Object.keys(data.data), false));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch employees availabilities error"}));
                } else {
                    dispatch(loadEmployeesAvailabilitiesFailureAction(error.message));
                }
            });
    };
}

const insertAvailabilitySuccessAction = (employeeId, payload) => ({
    type: ActionTypes.UPDATE_SUCCESS,
    employeeId,
    payload
});

/**
 * handle availability slot insert
 * @param {string} employeeId
 * @param {Object} payload
 * @return {function}
 */
function insertAvailabilityAction(employeeId, payload) {
    return function (dispatch) {
        dispatch(insertAvailabilitySuccessAction(employeeId, payload));
    };
}

const updateAvailabilitySuccessAction = (payload) => ({
    type: ActionTypes.UPDATE_SUCCESS,
    payload
});

/**
 * handle availability slot update
 * @param {Object} payload
 * @return {function}
 */
function updateAvailabilityAction(payload) {
    return function (dispatch) {
        dispatch(updateAvailabilitySuccessAction(payload));
    };
}

const deleteAvailabilitySuccessAction = (empId, slotId) => ({
    type: ActionTypes.DELETE_SUCCESS,
    empId,
    slotId
});

/**
 * handle availability slot delete
 * @param {string} employeeId
 * @param {string} slotId
 * @return {function}
 */
function deleteAvailabilityAction(employeeId, slotId) {
    return function (dispatch) {
        dispatch(deleteAvailabilitySuccessAction(employeeId, slotId));
    };
}

/**
 * save filtered employee ids
 * @param {Array.<String>} practitionerIds The list of practitionerIds
 * @return {{type: string, payload: *}}
 */
function saveFilteredIds(practitionerIds) {
    return {type: ActionTypes.SAVE_FILTERED_EMPLOYEES, practitionerIds};
}
/**
 * fetch availability setting
 * @param {object} settings
 * @param {"fromHealthcareService"|"fromPractitionerRole"} settings.availabilityMode
 * @return {{type: string, payload: *}}
 */
function fetchAvailabilitySettingSuccessAction(settings) {
    return {type: ActionTypes.FETCH_SETTING, settings};
}

/**
 * fetch availability setting
 * @param {string} organizationId
 * @return {function}
 */
function fetchAvailabilitySetting(organizationId) {
    return function (dispatch) {
        fetchSettingAPI(organizationId)
            .then(({data}) => {
                if (data.data && data.data.settings) {
                    dispatch(fetchAvailabilitySettingSuccessAction(data.data.settings));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch employees availabilities error"}));
                }
            });
    };
}

export {
    deleteAvailabilityAction,
    loadEmployeesAvailabilitiesAction,
    insertAvailabilityAction,
    updateAvailabilityAction,
    saveFilteredIds,
    fetchAvailabilitySetting
};
