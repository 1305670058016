/**
 * @fileoverview redux reducer for availability
 */

import cloneDeep from "lodash/cloneDeep";

import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./employee_availability_action_types";

// validation results for added slots will be directly set with id as a key
// [slotId]: {preCalcDates, conflicts} if a slot is new, slotId will be the newSlotIndex.

const initialState = {
    slots: [],
    status: STATUS.IDLE,
    validateStatus: STATUS.IDLE,
    error: null,
    added: {}, // added slots, key: newSlotIndex, value: slotId, ex. {0: "slotId"}
    deleted: [], // used for added and then deleted slots
    countNewSlots: 0
};

const handleClear = (state) => ({
    slots: [],
    status: STATUS.IDLE,
    validateStatus: STATUS.IDLE,
    error: null,
    added: {},
    deleted: [],
    countNewSlots: 0
});

const handleClearValidate = (state, {id}) => {
    delete state[id];
    return {
        ...state,
        validateStatus: STATUS.IDLE
    };
};

const handleLoadRequest = (state) => ({
    ...state,
    status: STATUS.PENDING,
    slots: [],
    error: null
});

const handleLoadFailure = (state, {error}) => ({
    ...state,
    status: STATUS.REJECTED,
    error
});

const handleLoadSuccess = (state, {payload}) => ({
    ...state,
    status: STATUS.RESOLVED,
    error: null,
    slots: payload
});

const handleDeleteRequest = (state) => ({
    ...state,
    status: STATUS.PENDING,
    error: null
});

const handleDeleteSuccess = (state, {id}) => {
    // remove slot from slots
    let slots = cloneDeep(state.slots);
    slots = slots.filter((slot) => slot._id !== id);

    // remove validation
    delete state[id];

    // add in deleted array
    const addedSlotsIds = Object.keys(state.added).map((key) => state.added[key]);
    const deleted = cloneDeep(state.deleted);
    if (addedSlotsIds && addedSlotsIds.includes(id)) {
        deleted.push(id);
    }

    return {
        ...state,
        status: STATUS.RESOLVED,
        error: null,
        slots,
        deleted
    };
};

const handleDeleteFailure = (state, {error}) => ({
    ...state,
    status: STATUS.REJECTED,
    error
});

const handleSaveRequest = (state) => ({
    ...state,
    status: STATUS.PENDING,
    error: null
});

/**
 * reducer to set countNewSlots - number of slots added in layer
 * @param {Object} state
 * @param {Object} param1
 * @param {Number} countNewSlots
 * @return {Object}
 */
const handleCountNewSlots = (state, {countNewSlots}) => {
    return {
        ...state,
        countNewSlots
    };
};

/**
 * reducer to save a slot
 * @param {Object} state
 * @param {Object} param1
 * @param {Number} newSlotIndex
 * @param {String} id
 * @return {Object}
 */
const handleSaveSuccess = (state, {newSlotIndex, id}) => {
    const added = cloneDeep(state.added);
    if (typeof newSlotIndex === "number" && id) {
        // only set if the slot is added
        added[newSlotIndex] = id;
    }
    return {
        ...state,
        status: STATUS.RESOLVED,
        error: null,
        added
    };
};

const handleSaveFailure = (state, {error}) => ({
    ...state,
    status: STATUS.REJECTED,
    error
});

const handleValidateRequest = (state) => ({
    ...state,
    validateStatus: STATUS.PENDING
});

const handleValidateSuccess = (state, {slotId, conflicts, preCalcDates}) => {
    return {
        ...state,
        validateStatus: STATUS.RESOLVED,
        [slotId]: {conflicts, preCalcDates}
    };
};

const handleValidateFailure = (state) => ({
    ...state,
    validateStatus: STATUS.REJECTED
});

const handlers = {
    [ActionTypes.CLEAR_SLOT]: handleClear,
    [ActionTypes.CLEAR_VALIDATE]: handleClearValidate,

    [ActionTypes.VALIDATE_SLOT_REQUEST]: handleValidateRequest,
    [ActionTypes.VALIDATE_SLOT_SUCCESS]: handleValidateSuccess,
    [ActionTypes.VALIDATE_SLOT_FAILURE]: handleValidateFailure,

    [ActionTypes.SAVE_SLOT_REQUEST]: handleSaveRequest,
    [ActionTypes.SAVE_SLOT_SUCCESS]: handleSaveSuccess,
    [ActionTypes.SAVE_SLOT_FAILURE]: handleSaveFailure,

    [ActionTypes.DELETE_SLOT_REQUEST]: handleDeleteRequest,
    [ActionTypes.DELETE_SLOT_SUCCESS]: handleDeleteSuccess,
    [ActionTypes.DELETE_SLOT_FAILURE]: handleDeleteFailure,

    [ActionTypes.LOAD_SLOT_REQUEST]: handleLoadRequest,
    [ActionTypes.LOAD_SLOT_SUCCESS]: handleLoadSuccess,
    [ActionTypes.LOAD_SLOT_FAILURE]: handleLoadFailure,

    [ActionTypes.SET_COUNT_NEW_SLOTS]: handleCountNewSlots
};

export default createReducer(initialState, handlers);
