// @ts-check
import {Button} from "@mui/material";
import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../../config/config.json";
import AbsenceIcon from "../../shared/icons/absence_icon";
import AvailableIcon from "../../shared/icons/available_icon";
import {setCountNewSlotsAction} from "../employee_availability_actions";
import {selectCountNewSlots} from "../employee_availability_selectors";
import useStyles from "./employee_availability_dialog.styles";
import EmployeeAvailabilityForm from "./employee_availability_form";

/**
 * render EmployeeAvailabilityDialog component
 *
 * @param {Object} props
 * @param {AvailabilityDefaultValues} props.defaultValues
 * @param {String} props.empId
 * @param {Boolean} props.isPending
 * @param {Array<AvailabilitySlot>} props.slots
 * @param {Function} props.onChange
 * @param {Function} props.onDelete
 * @param {Function} props.onSave
 * @param {Variant} props.variant
 * @param {Number} props.numSlots
 * @return {React.ReactElement}
 */
const EmployeeAvailabilityDialog = ({defaultValues, empId, isPending, slots, onChange, onDelete, onSave, variant, numSlots}) => {
    const {t} = useTranslation();
    const {classes} = useStyles();
    const dispatch = useDispatch();

    // Redux
    const countNewSlots = useSelector(selectCountNewSlots);

    useEffect(() => {
        // If user clicked on a cell of the canvas and existing slots are 0, set one empty form
        if (empId && numSlots === 0) {
            dispatch(setCountNewSlotsAction(1));
        }

        // If user clicked on the right action menu, set one empty form
        if (empId === undefined) {
            dispatch(setCountNewSlotsAction(1));
        }
    }, [empId, numSlots]);

    /**
     * handler to add an availability or an absence
     */
    const handleAdd = () => {
        dispatch(setCountNewSlotsAction(countNewSlots + 1));
    };

    const isAvailable = variant === config.AVAILABILITY_VARIANTS.AVAILABILITY;
    const IconName = isAvailable ? AvailableIcon : AbsenceIcon;

    const newSlotForms = [];
    for (let index = 0; index < countNewSlots; index++) {
        newSlotForms.push(
            <div key={"New-" + index}>
                <EmployeeAvailabilityForm
                    defaultValues={defaultValues}
                    empId={empId}
                    isPending={isPending}
                    newSlotIndex={index + 1}
                    variant={variant}
                    onChange={onChange}
                    onDelete={onDelete}
                    onSave={onSave}
                />
                <div className={classes.divider} />
            </div>
        );
    }
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.title}>
                    <div className={classes.titleText}>
                        <IconName className={classes.icon} size="1.2rem" />
                        {isAvailable ? t("EmployeeAvailabilityForm.available") : t("EmployeeAvailabilityForm.unavailable")}
                    </div>
                    <Button className={classes.button} color="primary" variant="contained" onClick={handleAdd}>
                        {t("EmployeeAvailabilityForm.add")}
                    </Button>
                </div>
                <div className={classes.headerDivider} />
                <div className={classes.formContainer}>
                    {slots &&
                        slots.map((slot) => (
                            <div key={slot._id}>
                                <EmployeeAvailabilityForm
                                    defaultValues={defaultValues}
                                    empId={empId}
                                    isPending={isPending}
                                    slot={slot}
                                    variant={variant}
                                    onChange={onChange}
                                    onDelete={onDelete}
                                    onSave={onSave}
                                />
                                <div className={classes.divider} />
                            </div>
                        ))}
                    {Boolean(newSlotForms) && newSlotForms}
                </div>
            </div>
        </div>
    );
};

EmployeeAvailabilityDialog.propTypes = {
    defaultValues: PropTypes.shape({}),
    empId: PropTypes.string,
    isPending: PropTypes.bool,
    slots: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            variant: PropTypes.string
        })
    ),
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    variant: PropTypes.string,
    numSlots: PropTypes.number
};

EmployeeAvailabilityDialog.defaultProps = {
    defaultValues: {}
};

export default EmployeeAvailabilityDialog;
