// @ts-check
/**
 * @fileoverview API for employee availability
 */

import axios from "../../middleware/axios.middelware";

const URL = "/orchestrator/pracroles";

/**
 * fetch availability slot of an employee
 * @param {number} organizationId
 * @param {String} pracRoleId
 * @param {Array} slotIds
 * @return {Promise}
 */
function fetchAvailabilitySlot(organizationId, pracRoleId, slotIds) {
    const params = {
        organizationId
    };

    return Promise.all(slotIds.map((slotId) => axios.get(`${URL}/${pracRoleId}/availability/${slotId}`, {params})));
}

/**
 * delete an availability slot of an employee
 * @param {number} organizationId
 * @param {Array} pracRoleIds
 * @param {String} slotId
 * @param {String} type
 * @return {Promise}
 */
function deleteAvailabilitySlot(organizationId, pracRoleIds, slotId, type) {
    const data = {
        organizationId,
        type,
        slotId,
        pracRoleIds
    };

    return axios.delete(`${URL}/availability`, {data});
}

/**
 * create an availability slot of an employee
 * @param {number} organizationId
 * @param {Array} pracRoleIds
 * @param {Object} data
 * @return {Promise}
 */
function createAvailabilitySlot(organizationId, pracRoleIds, data) {
    const newData = {
        organizationId,
        pracRoleIds,
        ...data
    };

    return axios.post(`${URL}/availability`, newData);
}

/**
 * update an availability slot of an employee
 * @param {number} organizationId
 * @param {Array} pracRoleIds
 * @param {String} slotId
 * @param {Object} data
 * @return {Promise}
 */
function updateAvailabilitySlot(organizationId, pracRoleIds, slotId, data) {
    const updatedData = {
        organizationId,
        pracRoleIds,
        slotId,
        ...data
    };

    return axios.post(`${URL}/availability`, updatedData);
}

/**
 * validate an availability slot of an employee without changing the data
 * @param {number} organizationId
 * @param {Array} pracRoleIds
 * @param {Object} data
 * @return {Promise}
 */
function validateAvailabilitySlot(organizationId, pracRoleIds, data) {
    const params = {
        organizationId,
        slotId: data.id,
        pracRoleIds,
        ...data
    };

    delete params.id;
    delete params.newSlotIndex;

    return axios.get(`${URL}/check/availability`, {
        params
    });
}

export {createAvailabilitySlot, deleteAvailabilitySlot, fetchAvailabilitySlot, updateAvailabilitySlot, validateAvailabilitySlot};
