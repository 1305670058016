import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
    list: {
        listStyleType: "none",
        margin: 0,
        padding: 0,
        width: "calc(100% - 1.75rem)"
    },
    entry: {
        paddingTop: theme.spacing("xxs"),
        paddingBottom: theme.spacing("xxs")
    },
    add: {
        "display": "flex",
        "justifyContent": "center",
        "& button": {
            visibility: "hidden"
        },
        "&:hover": {
            "& button": {
                visibility: "visible"
            }
        }
    },
    addVisible: {
        "& button": {
            visibility: "visible"
        }
    },
    addIcon: {
        color: theme.palette.primary.main
    },
    disabled: {
        color: theme.palette.text.disabled
    },
    menu: {
        lineHeight: "1.5rem",
        display: "flex",
        alignItems: "center"
    },
    availableIcon: {
        fill: theme.palette.primary.main
    },
    cursor: {
        cursor: "pointer"
    }
}));
