/**
 * @fileoverview redux actions for availabilities
 */

import {authUserFailureAction} from "../../redux/actions/index";
import {selectCurrentTimezone, selectCurrentUserEmail} from "../../redux/app_selectors";
import logger from "../../utils/logger_pino";
import {areSameDates, getDateTimeFromISO} from "../../utils/luxon_helpers";
import {deleteAvailabilityAction, loadEmployeesAvailabilitiesAction} from "../employees_availabilities";
import ActionTypes from "./employee_availability_action_types";
import {
    createAvailabilitySlot,
    deleteAvailabilitySlot,
    fetchAvailabilitySlot,
    updateAvailabilitySlot,
    validateAvailabilitySlot
} from "./employee_availability_api";

const clearSlotAction = () => ({
    type: ActionTypes.CLEAR_SLOT
});

const clearValidateAction = (id) => ({
    type: ActionTypes.CLEAR_VALIDATE,
    id
});

const loadAvailabilitySlotRequestAction = () => ({
    type: ActionTypes.LOAD_SLOT_REQUEST
});

const loadAvailabilitySlotSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_SLOT_SUCCESS,
    payload
});

const loadAvailabilitySlotFailureAction = (error) => ({
    type: ActionTypes.LOAD_SLOT_FAILURE,
    error
});

/**
 * load slots
 * @param {number} organizationId
 * @param {String} pracRoleId
 * @param {Array} slotIds
 * @return {function}
 */
function loadAvailabilitySlotAction(organizationId, pracRoleId, slotIds) {
    return function (dispatch) {
        dispatch(loadAvailabilitySlotRequestAction());

        fetchAvailabilitySlot(organizationId, pracRoleId, slotIds)
            .then((res) => {
                const slots = res.map((data) => data.data.data.slot);
                dispatch(loadAvailabilitySlotSuccessAction(slots));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch availabilities error"}));
                } else {
                    dispatch(loadAvailabilitySlotFailureAction(error.message));
                }
            });
    };
}

const saveAvailabilitySlotRequestAction = () => ({
    type: ActionTypes.SAVE_SLOT_REQUEST
});

const saveAvailabilitySlotSuccessAction = (newSlotIndex, id) => ({
    type: ActionTypes.SAVE_SLOT_SUCCESS,
    newSlotIndex,
    id
});

const saveAvailabilitySlotFailureAction = (error) => ({
    type: ActionTypes.SAVE_SLOT_FAILURE,
    error
});

const getSavedSlotFromDocument = (data, responseData, timezone) => {
    const type = data.type === "absence" ? "next_absence" : "next_availability";

    let slot = {};
    if (responseData[type]) {
        slot = responseData[type].find((item) => {
            const sameFrequency = item.interval === "custom" ? item.frequency === data.frequency : true;
            const sameRepeatAfterDays = item.interval === "custom" ? item.repeatAfterDays === data.repeatAfterDays : true;

            return (
                areSameDates(getDateTimeFromISO(item.startDate), getDateTimeFromISO(data.startDate), "day", timezone) &&
                areSameDates(getDateTimeFromISO(item.endDate), getDateTimeFromISO(data.endDate), "day", timezone) &&
                item.hourStartLocalTime === data.hourStartLocalTime &&
                item.hourEndLocalTime === data.hourEndLocalTime &&
                item.interval === data.interval &&
                sameFrequency &&
                sameRepeatAfterDays
            );
        });
    }
    delete slot.preCalcDates;
    return {
        ...slot,
        type: data.type
    };
};

/**
 * save a slot
 * @param {Number} organizationId
 * @param {Array} pracRoleIds
 * @param {String} slotId
 * @param {String} data
 * @return {function}
 */
function saveAvailabilitySlotAction(organizationId, pracRoleIds, slotId, data) {
    return function (dispatch, getState) {
        dispatch(saveAvailabilitySlotRequestAction());
        const email = selectCurrentUserEmail(getState());
        const timezone = selectCurrentTimezone(getState());
        if (slotId) {
            updateAvailabilitySlot(organizationId, pracRoleIds, slotId, data)
                .then(({data}) => {
                    dispatch(saveAvailabilitySlotSuccessAction());
                    // trigger refresh all employee's availabilities
                    dispatch(loadEmployeesAvailabilitiesAction(organizationId));
                })
                .catch((error) => {
                    logger.error(error, {organizationId, email});
                    if (error.response && error.response.status === 401) {
                        dispatch(authUserFailureAction({error: true, message: "update availability error"}));
                    } else {
                        dispatch(saveAvailabilitySlotFailureAction(error.message));
                    }
                });
        } else {
            createAvailabilitySlot(organizationId, pracRoleIds, data)
                .then((res) => {
                    const savedSlot = getSavedSlotFromDocument(data, res.data && res.data.data && res.data.data[0], timezone);
                    // Save id of the added slot
                    dispatch(saveAvailabilitySlotSuccessAction(data.newSlotIndex, savedSlot._id));
                    // trigger refresh all employee's availabilities
                    dispatch(loadEmployeesAvailabilitiesAction(organizationId));
                })
                .catch((error) => {
                    logger.error(error, {organizationId, email});
                    if (error.response && error.response.status === 401) {
                        dispatch(authUserFailureAction({error: true, message: "create availability error"}));
                    } else {
                        dispatch(saveAvailabilitySlotFailureAction(error.message));
                    }
                });
        }
    };
}

const deleteAvailabilitySlotRequestAction = () => ({
    type: ActionTypes.DELETE_SLOT_REQUEST
});

const deleteAvailabilitySlotSuccessAction = (id) => ({
    type: ActionTypes.DELETE_SLOT_SUCCESS,
    id
});

const deleteAvailabilitySlotFailureAction = (error) => ({
    type: ActionTypes.DELETE_SLOT_FAILURE,
    error
});

/**
 * delete a slot
 * @param {Number} organizationId
 * @param {Array} pracRoleIds
 * @param {String} slotId
 * @param {String} type
 * @param {String} practitionerId
 * @return {function}
 */
function deleteAvailabilitySlotAction(organizationId, pracRoleIds, slotId, type, practitionerId) {
    return function (dispatch) {
        dispatch(deleteAvailabilitySlotRequestAction());

        deleteAvailabilitySlot(organizationId, pracRoleIds, slotId, type)
            .then((res) => {
                dispatch(deleteAvailabilitySlotSuccessAction(slotId));
                // trigger delete in all employee's availabilities
                dispatch(deleteAvailabilityAction(practitionerId, slotId));
                dispatch(loadEmployeesAvailabilitiesAction(organizationId));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "delete availability error"}));
                } else {
                    dispatch(deleteAvailabilitySlotFailureAction(error.message));
                }
            });
    };
}

const validateAvailabilitySlotRequestAction = () => ({
    type: ActionTypes.VALIDATE_SLOT_REQUEST
});

const validateAvailabilitySlotSuccessAction = (slotId, conflicts, preCalcDates) => ({
    type: ActionTypes.VALIDATE_SLOT_SUCCESS,
    slotId,
    conflicts,
    preCalcDates
});

const validateAvailabilitySlotFailureAction = () => ({
    type: ActionTypes.VALIDATE_SLOT_FAILURE
});

/**
 * validate a slot change
 * @param {Number} organizationId
 * @param {Array} pracRoleIds
 * @param {Object} slot
 * @return {function}
 */
function validateAvailabilitySlotAction(organizationId, pracRoleIds, slot) {
    return function (dispatch) {
        dispatch(validateAvailabilitySlotRequestAction());

        validateAvailabilitySlot(organizationId, pracRoleIds, slot)
            .then(({data}) => {
                // success? validation passed
                dispatch(validateAvailabilitySlotSuccessAction(slot.id || slot.newSlotIndex, data.data.conflicts, data.data.preCalcDates));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "validate availability error"}));
                } else {
                    // error? validation failed
                    dispatch(validateAvailabilitySlotFailureAction());
                }
            });
    };
}

const setCountNewSlotsAction = (countNewSlots) => ({
    type: ActionTypes.SET_COUNT_NEW_SLOTS,
    countNewSlots
});

export {
    clearSlotAction,
    clearValidateAction,
    deleteAvailabilitySlotAction,
    loadAvailabilitySlotAction,
    saveAvailabilitySlotAction,
    validateAvailabilitySlotAction,
    setCountNewSlotsAction
};
