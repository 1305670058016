// @ts-check
import {MoreVert} from "@mui/icons-material";
import {IconButton, Menu, MenuItem} from "@mui/material";
import {arrayOf, bool, func, object, shape, string} from "prop-types";
import React, {useContext, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";

import config from "../../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../../contexts/dates";
import {selectCurrentUserPractitionerId} from "../../../../redux/app_selectors";
import {PERMISSION, useSecurity} from "../../../../utils/security";
import AbsenceIcon from "../../../shared/icons/absence_icon";
import AvailableIcon from "../../../shared/icons/available_icon";
import PermissionTooltip from "../../../shared/permission_tooltip/permission_tooltip";
import {selectAvailabilityMode} from "../../employees_availabilities_selectors";
import Period from "../period";
import useStyles from "./availabilities_list_item.styles";
import {sortPeriods} from "./helpers";

/**
 * @typedef {Object} Hovered
 * @property {string} practitionerId
 * @property {Object} date
 */

/**
 *
 * @param {Object} props
 * @param {Array<DataSlotAvailabilities>} props.availabilities
 * @param {Array<DataSlotAbsences>} props.absences
 * @param {boolean} props.isPending
 * @param {DateTimeType} props.date
 * @param {Function} props.onAddClick
 * @param {String} props.practitionerId
 * @return {React.ReactElement}
 */
const AvailabilitiesListItem = ({availabilities, absences, isPending, date, onAddClick, practitionerId}) => {
    const {classes, cx} = useStyles();
    const {isGranted} = useSecurity();
    const {t} = useTranslation();
    const {fromJSDate, areSame, fromISO, format} = useContext(DateContext);

    const availabilityMode = useSelector(selectAvailabilityMode, shallowEqual);
    const practitionerIdUser = useSelector(selectCurrentUserPractitionerId, shallowEqual);

    const [anchorEl, setAnchorEl] = useState(null);
    /**
     * @type {[Hovered, Function]} HoveredState
     */
    const [hovered, setHovered] = useState({practitionerId: null, date: null});
    const isHovered = hovered?.practitionerId === practitionerId && areSame(fromJSDate(hovered.date), date, "day");

    /**
     * handler for mouse over, set hoveredOp in redux
     *
     * @param {String} practitionerId
     * @param {DateTimeType} date
     * @return {Function}
     */
    const handleMouseOver = (practitionerId, date) => setHovered({practitionerId, date});

    /**
     * handler for mouse leave, reset hoveredOp in redux (TO ASK NOBUKO: In redux? Is this right?)
     *
     * @return {Function}
     */
    const handleMouseLeave = () => setHovered({});

    const open = Boolean(anchorEl);

    const isAllowed =
        isGranted(PERMISSION.MODIFY_PRACTITIONER) ||
        (isGranted(PERMISSION.MODIFY_PRACTITIONER_OWN) && practitionerId === practitionerIdUser);

    const periodAvailabilities = useMemo(
        () =>
            availabilities
                .filter((availability) => areSame(fromISO(availability.start), date, "day"))
                .map((entry) => ({...entry, status: config.AVAILABILITY_VARIANTS.AVAILABILITY})),
        [availabilities, date]
    );
    // Sort within availabilities
    periodAvailabilities.sort(sortPeriods);

    const periodAbsences = [];
    absences
        .filter((absence) => areSame(fromISO(absence.start), date, "day"))
        .forEach((entry) => {
            // remove absences with duplicate Ids
            if (!periodAbsences.find((item) => item.slotId === entry.slotId)) {
                periodAbsences.push({...entry, status: config.AVAILABILITY_VARIANTS.ABSENCE});
            }
        });
    // Sort within absences
    periodAbsences.sort(sortPeriods);

    const handleClick = (e) => isAllowed && setAnchorEl(e.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const handleClickAvailability = () => {
        onAddClick(config.AVAILABILITY_VARIANTS.AVAILABILITY, periodAvailabilities);
        handleClose();
    };

    const handleClickAbsence = () => {
        onAddClick(config.AVAILABILITY_VARIANTS.ABSENCE, periodAbsences);
        handleClose();
    };

    const modifyButton = (
        <div
            className={cx(classes.add, {
                [classes.addVisible]: isHovered,
                [classes.cursor]: isAllowed
            })}
            data-testid={`modify-${practitionerId}-${format(date, DATE_FORMATS.SYSTEM_DATE)}`}
            role={"button"}
            tabIndex={0}
            onClick={handleClick}
            onKeyDown={(e) => e.key === "Enter" && handleClick(e)}
        >
            <PermissionTooltip isAllowed={isAllowed}>
                <IconButton disabled={!isAllowed || isPending} size="large">
                    <MoreVert color={isAllowed ? "primary" : "disabled"} fontSize="small" />
                </IconButton>
            </PermissionTooltip>
        </div>
    );

    return (
        <div className={classes.root} onMouseEnter={() => handleMouseOver(practitionerId, date)} onMouseLeave={handleMouseLeave}>
            <ul className={classes.list}>
                {periodAbsences.concat(periodAvailabilities).map(({start, end, pracRoleId, status, disciplineColor}) => (
                    <li className={classes.entry} key={`${start}-${end}:${pracRoleId} (${status})`}>
                        <Period from={start} highlightColor={disciplineColor} to={end} variant={status} />
                    </li>
                ))}
            </ul>
            {modifyButton}
            {isAllowed && (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    open={open}
                    transformOrigin={{vertical: "top", horizontal: "right"}}
                    onClose={handleClose}
                >
                    {(!availabilityMode || !config.DISABLE_AVAILABILITY_MODES.includes(availabilityMode)) && (
                        <MenuItem key="availability" onClick={handleClickAvailability}>
                            <div className={classes.menu}>
                                <AvailableIcon className={classes.availableIcon} size="1.2rem" />
                                &nbsp;{t("AvailabilityPlannerPage.available")}
                            </div>
                        </MenuItem>
                    )}
                    <MenuItem key="absence" onClick={handleClickAbsence}>
                        <div className={classes.menu}>
                            <AbsenceIcon size="1.2rem" />
                            &nbsp;{t("AvailabilityPlannerPage.unavailable")}
                        </div>
                    </MenuItem>
                </Menu>
            )}
        </div>
    );
};
const period = shape({
    pracRoleId: string.isRequired,
    disciplineColor: string,
    start: string.isRequired,
    end: string.isRequired
});
AvailabilitiesListItem.propTypes = {
    availabilities: arrayOf(period),
    absences: arrayOf(period),
    isPending: bool,
    onAddClick: func.isRequired,
    date: object,
    practitionerId: string
};

export default AvailabilitiesListItem;
