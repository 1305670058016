// @ts-check
/**
 * @fileoverview redux container for employee select
 */

import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {selectCurrentOrganizationId} from "../../../redux/app_selectors";
import {selectFullNamesArray} from "../../private_data/private_data_selectors";
import EmployeeSelect from "../components/employee_select";
import {loadEmployeesAction} from "../employees_actions";
import {selectEmployees} from "../employees_selectors";
import useStyles from "./employee_select_container.styles";

const EmployeeSelectContainer = ({className, disabled, label, placeholder, value, onChange}) => {
    const dispatch = useDispatch();
    const {classes} = useStyles();

    const organizationId = useSelector(selectCurrentOrganizationId);
    const employees = useSelector(selectEmployees);
    const practitionerNamesArray = useSelector(selectFullNamesArray({ids: Object.keys(employees) || [], type: "practitioner"}));

    useEffect(() => {
        dispatch(loadEmployeesAction(organizationId));
    }, [organizationId]);

    const renderValue = (empId) => {
        const practitioner = practitionerNamesArray.find((el) => el.id === empId);
        if (empId === "" || !practitioner) {
            return <div className={classes.disabled}>{placeholder}</div>;
        } else {
            return practitioner.name;
        }
    };

    const options = practitionerNamesArray
        .map(({id, name}) => ({
            value: id,
            label: name
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1));

    return (
        <EmployeeSelect
            className={className}
            disabled={disabled}
            label={label}
            options={options}
            placeholder={placeholder}
            renderValue={renderValue}
            value={value}
            onChange={onChange}
        />
    );
};

EmployeeSelectContainer.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default EmployeeSelectContainer;
