// @ts-check
/**
 * @fileoverview single period display
 */

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React, {Fragment, useContext} from "react";
import {useTranslation} from "react-i18next";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import useStyles from "./period.styles";

/**
 * Period component
 * @param {Object} props
 * @param {String} props.highlightColor
 * @param {Object} props.from
 * @param {Object} props.to
 * @param {"availability"|"absence"} props.variant
 * @return {React.ReactElement}
 */
const Period = ({highlightColor, from, to, variant}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const {formatFromISO} = useContext(DateContext);

    const start = formatFromISO(from, DATE_FORMATS.TIME);
    const end = formatFromISO(to, DATE_FORMATS.TIME);
    const startEnd = `${start} - ${end}`;
    let allDay = null;
    if (start === "00:00" && end === "23:59") {
        allDay = t("EmployeeAvailabilityForm.allDay");
    }
    return (
        <Box
            className={cx(classes.root, {
                [classes.available]: variant === config.AVAILABILITY_VARIANTS.AVAILABILITY,
                [classes.unavailable]: variant === config.AVAILABILITY_VARIANTS.ABSENCE
            })}
            title={startEnd}
        >
            {variant === config.AVAILABILITY_VARIANTS.AVAILABILITY && highlightColor && (
                <div className={classes.highlight} style={{backgroundColor: highlightColor}} />
            )}
            {allDay || (
                <Fragment>
                    <span>{start}</span>
                    <span className={classes.separator}>&ndash;</span>
                    <span>{end}</span>
                </Fragment>
            )}
        </Box>
    );
};

Period.propTypes = {
    highlightColor: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    variant: PropTypes.oneOf([config.AVAILABILITY_VARIANTS.AVAILABILITY, config.AVAILABILITY_VARIANTS.ABSENCE]).isRequired
};

export default Period;
