import buildFullName from "../private_data/utils/build_fullname";

/**
 * Check if a first or last name starts with the given text
 * The second or further given names are shown in the initial format, but filtered by the full given name - to be determined
 * @example Dietrich, Johanna Helga --> shown in the canvas as Dietrich, Johanna H. but if it's filtered by the text "he" the function will return true and shown
 *
 * @param {{given: Array<string>, family: string}} nameObject
 * @param {string} id
 * @param {string} searchTerm
 * @return {boolean}
 */
export const isNameStartsWithGivenTerm = (nameObject, id, searchTerm) => {
    const searchTermLowerCase = searchTerm?.toLowerCase();
    return !!(
        nameObject?.family?.toLowerCase()?.startsWith(searchTermLowerCase) ||
        nameObject?.given?.some((given) => given?.toLowerCase()?.startsWith(searchTermLowerCase)) ||
        buildFullName(nameObject, id)?.toLowerCase().startsWith(searchTermLowerCase)
    );
};
