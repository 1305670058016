// @ts-check
import {TableCell, TableRow} from "@mui/material";
import {array, bool, func, number, object, string} from "prop-types";
import React, {useContext} from "react";
import {shallowEqual, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../../contexts/dates";
import {selectAllFullNamesObject} from "../../../private_data/private_data_selectors";
import useStyles from "../week_table.styles";
import AvailabilitiesListItem from "./availabilities_list_item";

/**
 * The row for each practitioner
 *
 * @param {object} props
 * @param {number} props.index
 * @param {Array<DateTimeType>} props.dateRange
 * @param {boolean} props.isPending
 * @param {Object} props.style
 * @param {String} props.timezone
 * @param {Function} props.handleAdd
 * @param {boolean} props.hasPractitionerId
 * @param {Array<EmployeeData>} props.dataOrganized
 * @param {boolean} props.showWeekend
 * @return {React.ReactElement}
 */
export const AvailabilitiesRow = ({
    index,
    style,
    dataOrganized,
    hasPractitionerId,
    timezone,
    isPending,
    handleAdd,
    dateRange,
    showWeekend
}) => {
    const {format} = useContext(DateContext);

    const {practitionerId, absences, availabilities} = dataOrganized[index];
    const practitionerNamesObject = useSelector(selectAllFullNamesObject({type: "practitioner"}), shallowEqual);

    const {classes, cx} = useStyles();
    const percentRow = 100 / (showWeekend ? 7 : 5);

    return (
        <TableRow className={classes.row} component="div" key={practitionerId} style={style}>
            <TableCell
                className={cx(classes.bodyCell, classes.leftColumnCell, index === 0 && hasPractitionerId && classes.userRow)}
                component="div"
                data-practitionerid={practitionerId}
                title={practitionerNamesObject[practitionerId]}
            >
                <span>{practitionerNamesObject[practitionerId]}</span>
            </TableCell>

            {dateRange.map((date) => (
                <TableCell
                    className={cx(
                        classes.bodyCell,
                        classes.hoverEffect,
                        classes.cellSize,
                        index === 0 && hasPractitionerId && classes.userRow
                    )}
                    component="div"
                    key={format(date, DATE_FORMATS.ISO_DATE)}
                    style={{width: `${percentRow}%`}}
                >
                    <AvailabilitiesListItem
                        absences={absences || []}
                        availabilities={availabilities || []}
                        date={date}
                        isPending={isPending}
                        practitionerId={practitionerId}
                        onAddClick={handleAdd(practitionerId, date)}
                    />
                </TableCell>
            ))}
        </TableRow>
    );
};
AvailabilitiesRow.propTypes = {
    index: number,
    style: object,
    dataOrganized: array,
    hasPractitionerId: bool,
    timezone: string,
    isPending: bool,
    handleAdd: func,
    dateRange: array,
    showWeekend: bool
};
