import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    wrapper: {
        height: "100%",
        width: "100%",
        overflow: "auto"
    },
    root: {
        flex: 1
    },
    openRightLayer: {
        width: "calc(100% + 500px)"
    },
    noData: {
        height: "3rem"
    },
    tableWrapper: {
        height: "100%"
    },
    tableHead: {height: "2.625rem"},
    tableBody: {height: "calc(100vh - 5.625rem)", width: "100vw", overflowX: "hidden"},
    headerCell: {
        "minWidth": "150px",
        "color": theme.palette.text.disabled,
        "fontSize": "0.75rem",
        "fontWeight": "normal",
        "backgroundColor": `${theme.custom.background.layer} !important`,
        "padding": `${theme.spacing("xs")} ${theme.spacing("s")}`,
        "textTransform": "uppercase",
        "borderLeft": `0.5px solid ${theme.palette.border}`,
        "borderBottom": `0.5px solid ${theme.palette.border}`,
        "&:first-of-type": {
            padding: theme.spacing("xs")
        }
    },
    alignRight: {
        textAlign: "right",
        paddingRight: "0.5rem !important"
    },
    bodyCell: {
        "borderLeft": `1px solid ${theme.palette.border}`,
        "borderBottom": `1px solid ${theme.palette.border}`,
        "borderTop": "3px solid transparent",
        "borderRight": "3px solid transparent",
        "backgroundColor": "#fff !important",
        "fontSize": "0.75rem !important",
        "padding": "0.25rem",
        "minWidth": "7rem",
        "height": "2.5rem",
        "color": theme.palette.grey[700],
        "textTransform": "uppercase",
        "&:first-of-type": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            padding: `${theme.spacing("xs")} ${theme.spacing("m")}`
        }
    },
    cellSize: {minWidth: "150px"},
    leftColumnCell: {width: "250px", minWidth: "250px", maxWidth: "250px"},
    userRow: {
        borderBottom: `5px solid ${theme.palette.border}`
    },
    hoverEffect: {
        "&:hover": {
            border: `2px solid ${theme.palette.primary.main}`
        }
    },
    date: {
        margin: theme.spacing("xxs"),
        fontSize: "12px"
    },
    today: {
        borderRadius: "9px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing("xxs")
    },
    weekday: {
        margin: theme.spacing("xxs"),
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.spacing("m"),
        paddingRight: theme.spacing("s")
    },
    active: {
        "& td": {
            borderTop: `2px solid ${theme.palette.primary.main}`,
            borderBottom: `2px solid ${theme.palette.primary.main}`
        },
        "& td:first-of-type": {
            borderLeft: `2px solid ${theme.palette.primary.main}`
        },
        "& td:last-of-type": {
            borderRight: `2px solid ${theme.palette.primary.main}`
        }
    },
    searchText: {
        "fontWeight": theme.typography.fontWeightBold,
        "color": theme.palette.grey[700],
        "&:before": {
            display: "inline",
            content: "'\"'"
        },
        "&:after": {
            display: "inline",
            content: "'\"'"
        }
    },
    noOpText: {
        display: "flex",
        justifyContent: "center"
    },
    inputWrapper: {
        "paddingLeft": theme.spacing("s"),
        "width": "100%",
        "margin": "0",
        "& svg": {
            fontSize: theme.spacing("m"),
            color: `${theme.palette.text.disabled} !important`
        }
    },
    input: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: theme.palette.text.disabled,
        width: "85%",
        [theme.breakpoints.down("lg")]: {
            width: "80%"
        },
        [theme.breakpoints.down("md")]: {
            width: "70%"
        }
    },
    row: {
        display: "table-row"
    },
    noScrollbar: {
        "scrollbarWidth": "none",
        "scrollbarColor": "transparent transparent",
        "&::-webkit-scrollbar": {
            display: "none"
        }
    }
}));
