// @ts-check
import {Backdrop} from "@mui/material";
import PropTypes from "prop-types";
import React, {Fragment, useEffect, useMemo} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

import {selectCurrentOrganizationId, selectCurrentTimezone} from "../../../redux/app_selectors";
import {isPending} from "../../../redux/utils/status";
import {sortWithLocale} from "../../../utils/sort_with_locale";
import {loadDisciplineOptionsAction} from "../../disciplines";
import {loadEmployeesAction} from "../../employees/employees_actions";
import {selectDisciplineColorsMap} from "../../fe_settings/fe_settings_selectors";
import Loading from "../../shared/loading";
import WeekTable from "../components/week_table";
import {fetchAvailabilitySetting, loadEmployeesAvailabilitiesAction} from "../employees_availabilities_actions";
import {selectEmployees, selectStatus} from "../employees_availabilities_selectors";
import useStyles from "./employees_availabilities_container.styles";

/**
 * EmployeesAvailabilitiesContainer
 * @param {object} props
 * @param {DateTimeType} props.from
 * @param {DateTimeType} props.to
 * @param {Function} props.onAdd
 * @param {Boolean} props.showWeekend
 * @param {Boolean} props.openRightLayer
 * @return {React.ReactElement}
 */
const EmployeesAvailabilitiesContainer = ({from, to, onAdd, showWeekend, openRightLayer}) => {
    const {classes} = useStyles();
    const dispatch = useDispatch();

    const organizationId = useSelector(selectCurrentOrganizationId);
    const currentTimezone = useSelector(selectCurrentTimezone);
    const employees = useSelector(selectEmployees, shallowEqual);
    const status = useSelector(selectStatus);
    const disciplineColors = useSelector(selectDisciplineColorsMap, shallowEqual);

    useEffect(() => {
        if (organizationId) {
            dispatch(fetchAvailabilitySetting(organizationId));
            dispatch(loadDisciplineOptionsAction(organizationId));
            dispatch(loadEmployeesAvailabilitiesAction(organizationId, from, to));
            dispatch(loadEmployeesAction(organizationId));
        }
    }, [organizationId, from, to]);

    const data = useMemo(
        () =>
            Object.keys(employees)
                .sort(sortWithLocale)
                .map((practitionerId) => {
                    const mergedAvailability = {
                        practitionerId,
                        absences: [],
                        availabilities: []
                    };
                    Object.keys(employees[practitionerId]).map((pracRoleId) => {
                        // Set disciplineColor
                        const discipline = employees[practitionerId][pracRoleId].healthcareService;
                        const disciplineColor = discipline ? disciplineColors[discipline] : null;

                        mergedAvailability.absences.push(
                            ...employees[practitionerId][pracRoleId].absences.map((item) => ({...item, disciplineColor, pracRoleId}))
                        );
                        mergedAvailability.availabilities.push(
                            ...employees[practitionerId][pracRoleId].availabilities.map((item) => ({...item, disciplineColor, pracRoleId}))
                        );
                    });
                    return mergedAvailability;
                }),
        [employees, disciplineColors]
    );
    return (
        <Fragment>
            {isPending(status) && (
                <Backdrop className={classes.backdrop} open>
                    <Loading />
                </Backdrop>
            )}
            <WeekTable
                data={data}
                from={from}
                isPending={isPending(status)}
                openRightLayer={openRightLayer}
                showWeekend={showWeekend}
                timezone={currentTimezone}
                to={to}
                onAdd={onAdd}
            />
        </Fragment>
    );
};

EmployeesAvailabilitiesContainer.propTypes = {
    from: PropTypes.object.isRequired, // DateTime
    to: PropTypes.object.isRequired, // DateTime
    onAdd: PropTypes.func.isRequired,
    showWeekend: PropTypes.bool.isRequired,
    openRightLayer: PropTypes.bool.isRequired
};

const propsAreEqual = (prevProps, nextProps) => {
    const isFromEqual = prevProps.from === nextProps.from;
    const isToEqual = prevProps.to === nextProps.to;
    const isOnAddEqual = prevProps.onAdd === nextProps.onAdd;
    const isShowWeekendEqual = prevProps.showWeekend === nextProps.showWeekend;
    const isOpenRightLayerEqual = prevProps.openRightLayer === nextProps.openRightLayer;
    return isFromEqual && isToEqual && isOnAddEqual && isShowWeekendEqual && isOpenRightLayerEqual;
};
export default React.memo(EmployeesAvailabilitiesContainer, propsAreEqual);
