import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    input: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary
    },
    inputDisabled: {
        color: theme.palette.text.disabled
    },
    menuItem: {
        fontSize: "0.875rem"
    },
    closeIcon: {
        "fontSize": theme.spacing("m"),
        "margin": `0 ${theme.spacing("s")}`,
        "borderRadius": theme.spacing("s"),
        "border": `1px solid ${theme.palette.grey[200]}`,
        "&:hover": {
            background: theme.palette.grey[100]
        }
    },
    menuWrapper: {
        "maxHeight": "24rem",
        "& .MuiInputBase-root:focus": {
            outline: "none !important"
        }
    },
    inputWrapper: {
        "marginLeft": theme.spacing("m"),
        "width": "calc(100% - 1rem)",
        "& svg": {
            fontSize: theme.spacing("m")
        }
    },
    placeholder: {
        color: theme.palette.grey[500]
    }
}));
