import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../../config/config.json";
import usePrevious from "../../../hooks/usePrevious";
import {selectCurrentOrganizationId} from "../../../redux/app_selectors";
import {isPending, isRejected, isResolved} from "../../../redux/utils/status";
import {selectEmployees} from "../../employees/employees_selectors";
import EmployeeAvailabilityDialog from "../components/employee_availability_dialog";
import {
    deleteAvailabilitySlotAction,
    loadAvailabilitySlotAction,
    saveAvailabilitySlotAction,
    validateAvailabilitySlotAction
} from "../employee_availability_actions";
import {selectError, selectSlots, selectStatus, selectValidateStatus} from "../employee_availability_selectors";

/**
 * render EmployeeAvailabilityDialogContainer component
 * @param {Object} props
 * @param {DateTimeType} props.dateFrom
 * @param {DateTimeType} props.dateTo
 * @param {String} props.empId
 * @param {Array<{slotId: String}>} props.periods
 * @param {Function} props.onDelete
 * @param {Function} props.onError
 * @param {Function} props.onSave
 * @param {Variant} props.variant
 * @return {React.ReactElement}
 */
const EmployeeAvailabilityDialogContainer = ({dateFrom, dateTo, empId, periods, onDelete, onError, onSave, variant}) => {
    const dispatch = useDispatch();

    // Redux
    const organizationId = useSelector(selectCurrentOrganizationId);
    const slots = useSelector(selectSlots);
    const status = useSelector(selectStatus);
    const previousStatus = usePrevious(status);
    const error = useSelector(selectError);
    const validateStatus = useSelector(selectValidateStatus);
    const pracRoleEmployees = useSelector(selectEmployees);

    // States
    const [isSaving, setSaving] = useState(false);

    useEffect(() => {
        if (periods && periods.length) {
            const pracRoleIds = empId ? pracRoleEmployees[empId].pracRoleIds : [];

            let pracRoleId = "";
            // If variant is absence, call only first pracRoleId
            if (variant === config.AVAILABILITY_VARIANTS.ABSENCE && pracRoleIds.length) {
                pracRoleId = pracRoleIds[0];
            }
            // @TODO #14768: if variant is available, all of the pracRoleIds must be called. Support edit availabilities

            dispatch(
                loadAvailabilitySlotAction(
                    organizationId,
                    pracRoleId,
                    periods.map((p) => p.slotId)
                )
            );
        }
    }, [empId, periods]);

    useEffect(() => {
        // successfully saved
        if (isSaving && isPending(previousStatus) && isResolved(status) && onSave) {
            setSaving(false);
            onSave();
        }
    }, [previousStatus, status, isSaving]);

    useEffect(() => {
        // an error occurred
        if (isSaving && isPending(previousStatus) && isRejected(status) && error && onError) {
            setSaving(false);
            onError();
        }
    }, [previousStatus, status, error, isSaving]);

    const handleChange = (data) => {
        // For absences, all pracRoledIds for the selected practitioner must be changed
        // @TODO #14786: for availabilities, pracRoleIds must be set from data. Support edit availabilities
        const pracRoleIds = empId || data.employee ? pracRoleEmployees[empId || data.employee]?.pracRoleIds : [];
        dispatch(validateAvailabilitySlotAction(organizationId, pracRoleIds, data));
    };

    const handleDelete = (slot, addedSlotId, type) => {
        // For absences, all pracRoledIds for the selected practitioner must be changed
        // @TODO #14786: for availabilities, pracRoleIds must be set from data. Support edit availabilities
        const pracRoleIds = empId || slot.employee ? pracRoleEmployees[empId || slot.employee]?.pracRoleIds : [];
        dispatch(deleteAvailabilitySlotAction(organizationId, pracRoleIds, slot._id || addedSlotId, type, empId || slot.employee));
        if (onDelete) {
            onDelete();
        }
    };

    const handleSave = (empId, data) => {
        // For absences, all pracRoledIds for the selected practitioner must be changed
        // @TODO #14786: for availabilities, pracRoleIds must be set from data. Support edit availabilities
        const pracRoleIds = empId || data.employee ? pracRoleEmployees[empId || data.employee]?.pracRoleIds : [];
        dispatch(saveAvailabilitySlotAction(organizationId, pracRoleIds, data.id, data));
        setSaving(true);
    };

    const defaultValues = {
        start: dateFrom,
        end: dateTo,
        interval: "none"
    };

    return (
        <EmployeeAvailabilityDialog
            defaultValues={defaultValues}
            empId={empId}
            isPending={isPending(status) || isPending(validateStatus)}
            numSlots={periods && periods.length}
            slots={slots}
            variant={variant}
            onChange={handleChange}
            onDelete={handleDelete}
            onSave={handleSave}
        />
    );
};

EmployeeAvailabilityDialogContainer.propTypes = {
    dateFrom: PropTypes.object,
    dateTo: PropTypes.object,
    empId: PropTypes.string,
    periods: PropTypes.array,
    onDelete: PropTypes.func,
    onError: PropTypes.func,
    onSave: PropTypes.func,
    variant: PropTypes.string
};

export default EmployeeAvailabilityDialogContainer;
