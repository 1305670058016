// @ts-check
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {arrayOf, bool, func, object, shape, string} from "prop-types";
import React, {useContext, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectCurrentUserPractitionerId} from "../../../redux/app_selectors";
import {isResolved} from "../../../redux/utils/status";
import {getDatesInPeriod} from "../../../utils/date_range";
import {selectAllFirstAndLastNamesObject, selectFullNamesArray} from "../../private_data/private_data_selectors";
import NoOp from "../../shared/no_op/no_op";
import SearchTextField from "../../shared/search_text_field/search_text_field";
import {saveFilteredIds} from "../employees_availabilities_actions";
import {selectFilteredPractitionerIds, selectStatus} from "../employees_availabilities_selectors";
import {isNameStartsWithGivenTerm} from "../helpers";
import {AvailabilitiesList} from "./availabilities_list";
import useStyles from "./week_table.styles";

const WEEKDAY_SATURDAY = 6;
const WEEKDAY_SUNDAY = 7;

/**
 * @param {object} props
 * @param {EmployeeData []} props.data
 * @param {Boolean} props.isPending
 * @param {DateTimeType} props.from
 * @param {DateTimeType} props.to
 * @param {Boolean} props.showWeekend
 * @param {String} props.timezone
 * @param {Function} props.onAdd
 * @param {Boolean} props.openRightLayer
 * @return {React.ReactElement}
 */
const WeekTable = ({data, isPending, from, showWeekend, to, timezone, onAdd, openRightLayer}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {getDT, format, areSame, now} = useContext(DateContext);

    // Redux
    const filteredPractitionerIds = useSelector(selectFilteredPractitionerIds);
    const status = useSelector(selectStatus);
    const practitionerIdUser = useSelector(selectCurrentUserPractitionerId);
    // @ts-ignore
    const practitionerFirstAndLastName = useSelector(selectAllFirstAndLastNamesObject({type: "practitioner"})); // used for search by name
    const employeeAvailabilityIds = data.map((empData) => empData.practitionerId);
    const practitionerNamesList = useSelector(selectFullNamesArray({type: "practitioner", ids: employeeAvailabilityIds}));

    const currentEmployee = data.find(({practitionerId}) => practitionerIdUser === practitionerId);
    const hasPractitionerId = Boolean(currentEmployee);

    // State
    const [searchSurgeons, setSearchSurgeons] = useState(""); // the text in the search field regardless of the enter key was clicked or not
    const [filter, setFilter] = useState(null); // the text after enter key was clicked
    const dateRange = getDatesInPeriod(from, to).filter(
        (date) => showWeekend || (getDT(date, "weekday") !== WEEKDAY_SATURDAY && getDT(date, "weekday") !== WEEKDAY_SUNDAY)
    );

    const handleFilterSurgeons = (value) => {
        const practitionerIdsFiltered = [];

        if (value?.length > 0) {
            for (const practitionerId of employeeAvailabilityIds) {
                // If the first or last name starts with the given string, show the practitioner
                // @link https://dev.azure.com/next-data-service/13394-nextOR/_wiki/wikis/13394-NextOR-Wiki/401/Search-behaviour-Surgeons
                if (isNameStartsWithGivenTerm(practitionerFirstAndLastName[practitionerId], practitionerId, value)) {
                    practitionerIdsFiltered.push(practitionerId);
                }
            }
            setFilter(value);
        } else {
            setFilter(null);
        }

        dispatch(saveFilteredIds(practitionerIdsFiltered));
    };
    const handleDeleteFilterSurgeons = () => {
        setSearchSurgeons("");
        dispatch(saveFilteredIds([]));
        setFilter(null);
    };

    const practitionerOptions = useMemo(
        () => practitionerNamesList.sort((a, b) => a.name.localeCompare(b.name, "de", {numeric: true, sensitivity: "base"})),
        [practitionerNamesList]
    );

    const filterOptions = (options, state) =>
        state.inputValue
            ? options.filter((option) =>
                  isNameStartsWithGivenTerm(
                      practitionerFirstAndLastName[practitionerOptions.find((el) => el.name === option)?.id],
                      practitionerOptions.find((el) => el.name === option)?.id,
                      state.inputValue
                  )
              )
            : options;
    const searchField = (
        <SearchTextField
            disableUnderline={true}
            filterOptions={filterOptions}
            key="search-surgeons"
            options={practitionerOptions}
            placeholder={t("AvailabilityPlannerPage.searchSurgeonsPlaceholder")}
            showSearchIcon
            styles={{inputWrapper: classes.inputWrapper, input: classes.input}}
            value={searchSurgeons}
            onChange={setSearchSurgeons}
            onDelete={handleDeleteFilterSurgeons}
            onEnterKey={handleFilterSurgeons}
        />
    );

    /**
     * @param {EmployeeData} data
     * @return {EmployeeData[]|[]}
     */
    const filterByPractitionerId = ({practitionerId}) => filteredPractitionerIds.includes(practitionerId);

    /**
     * Search for the specified practitioner if a search term is set, otherwise returns the data
     *
     * @param {EmployeeData[]} data
     * @return {[EmployeeData]|[]|EmployeeData[]} filteredData
     */
    const filterData = (data) => (filter ? data.filter(filterByPractitionerId) : data);
    const dataToFilter = hasPractitionerId ? data.filter(({practitionerId}) => practitionerIdUser !== practitionerId) : data;
    /** @type {Array<EmployeeData>} */
    const dataFiltered = filterData(dataToFilter);
    const dataFilteredAndSorted = [...dataFiltered].sort((a, b) => {
        const keyA = practitionerNamesList.find((nameObject) => nameObject.id === a.practitionerId)?.name || a.practitionerId;
        const keyB = practitionerNamesList.find((nameObject) => nameObject.id === b.practitionerId)?.name || b.practitionerId;
        return keyA.localeCompare(keyB, "de", {
            numeric: true,
            sensitivity: "base"
        });
    });

    let dataOrganized;
    if (hasPractitionerId) {
        dataOrganized = data.length > 0 ? [currentEmployee, ...dataFilteredAndSorted] : [currentEmployee];
    } else {
        dataOrganized = dataFilteredAndSorted;
    }
    const text1 =
        data.length && filter ? (
            <div className={classes.noOpText}>
                {t("App.noSearchResult1")}&nbsp;
                <div className={classes.searchText}>{filter}</div>
            </div>
        ) : (
            t("App.noAvailabilities")
        );

    const text2 = data.length && filter ? t("App.noSearchResult2") : null;

    return (
        <div className={classes.wrapper}>
            <TableContainer
                className={cx(classes.root, {
                    [classes.noData]: !dataFiltered.length,
                    [classes.openRightLayer]: openRightLayer
                })}
            >
                <Table className={classes.tableWrapper} component="div" size="small" stickyHeader>
                    <TableHead className={classes.tableHead} component="div">
                        <TableRow component="div">
                            <TableCell className={cx(classes.headerCell, classes.leftColumnCell)} component="div">
                                {searchField}
                            </TableCell>
                            {dateRange.map((date) => (
                                <TableCell className={classes.headerCell} component="div" key={format(date, DATE_FORMATS.ISO_DATE)}>
                                    <span className={classes.weekday}>{format(date, DATE_FORMATS.WEEK_NAME)}</span>
                                    <span
                                        className={cx(classes.date, {
                                            [classes.today]: areSame(date, now(), "day")
                                        })}
                                    >
                                        {format(date, DATE_FORMATS.DATE_SHORT)}
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody} component="div">
                        {dataOrganized.length > 0 && (
                            <AvailabilitiesList
                                dataOrganized={dataOrganized}
                                dateRange={dateRange}
                                isPending={isPending}
                                showWeekend={showWeekend}
                                timezone={timezone}
                                onAdd={onAdd}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {isResolved(status) && !dataFiltered.length && <NoOp height={"calc(100% - 3rem)"} text1={text1} text2={text2} />}
        </div>
    );
};

const slotType = shape({
    start: string.isRequired,
    end: string.isRequired,
    disciplineColor: string,
    pracRoleId: string
});
WeekTable.propTypes = {
    data: arrayOf(
        shape({
            absences: arrayOf(slotType),
            availabilities: arrayOf(slotType),
            practitionerId: string.isRequired
        })
    ).isRequired,
    isPending: bool.isRequired,
    from: object.isRequired,
    to: object.isRequired,
    timezone: string.isRequired,
    onAdd: func.isRequired,
    showWeekend: bool.isRequired,
    openRightLayer: bool.isRequired
};
export default React.memo(WeekTable);
