/**
 * @fileoverview redux action types for availabilities
 */

const ActionTypes = {
    LOAD_REQUEST: "employees_availabilities/LOAD_EMPLOYEES_AVAILABILITIES_REQUEST",
    LOAD_SUCCESS: "employees_availabilities/LOAD_EMPLOYEES_AVAILABILITIES_SUCCESS",
    LOAD_FAILURE: "employees_availabilities/LOAD_EMPLOYEES_AVAILABILITIES_FAILURE",

    INSERT_SUCCESS: "employees_availabilities/INSERT_AVAILABILITY_SUCCESS",
    UPDATE_SUCCESS: "employees_availabilities/UPDATE_AVAILABILITY_SUCCESS",

    DELETE_SUCCESS: "employees_availabilities/DELETE_AVAILABILITY_SUCCESS",

    SAVE_FILTERED_EMPLOYEES: "employees_availabilities/SAVE_FILTERED_EMPLOYEES",

    FETCH_SETTING: "employees_availabilities/FETCH_SETTING"
};

export default ActionTypes;
