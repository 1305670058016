/**
 * @fileoverview redux selectors for employee's availabilities
 */

import {createSelector} from "reselect";

const selectEmployeesAvailabilitiesState = (state) => state.employeesAvailabilities;

const selectFromDate = (state) => selectEmployeesAvailabilitiesState(state).fromDate;
const selectToDate = (state) => selectEmployeesAvailabilitiesState(state).toDate;

const selectError = (state) => selectEmployeesAvailabilitiesState(state).error;

const selectStatus = (state) => selectEmployeesAvailabilitiesState(state).status;

const selectEmployees = (state) => selectEmployeesAvailabilitiesState(state).employees;

const selectEmployeeAvailabilityIds = createSelector(selectEmployees, (state) => (state ? Object.keys(state) : []));

const selectFilteredPractitionerIds = (state) => selectEmployeesAvailabilitiesState(state).filteredPractitionerIds;

const selectAvailabilityMode = (state) => selectEmployeesAvailabilitiesState(state).availabilityMode;

export {
    selectEmployees,
    selectEmployeeAvailabilityIds,
    selectError,
    selectStatus,
    selectFromDate,
    selectToDate,
    selectFilteredPractitionerIds,
    selectAvailabilityMode
};
