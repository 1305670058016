/**
 * @fileoverview API for employees
 */

import axiosClient from "../../middleware/axios.middelware";

const URL_EMPLOYEES = "/orchestrator/pracroles/practitioners";

/**
 * fetch employees
 * @param {number} organizationId
 * @return {Promise}
 */
function fetchEmployees(organizationId) {
    const queryParams = {
        organizationId
    };
    return axiosClient.get(URL_EMPLOYEES, {params: queryParams});
}

export {fetchEmployees};
