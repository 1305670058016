// @ts-check
import {FormControlLabel, Switch} from "@mui/material";
import Button from "@mui/material/Button";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectCurrentUserPractitionerId} from "../../../redux/app_selectors";
import {isResolved} from "../../../redux/utils/status";
import {convertHourMinuteDateTimeToHours, setLocalHourToDateTime} from "../../../utils/luxon_helpers";
import {PERMISSION, useSecurity} from "../../../utils/security";
import {EmployeeSelectContainer} from "../../employees";
import {selectAvailabilityMode} from "../../employees_availabilities/employees_availabilities_selectors";
import {selectThemeStartTimeOfTheDay} from "../../fe_settings/fe_settings_selectors";
import CommonSelector from "../../shared/common_selector/common_selector";
import CustomInterval from "../../shared/custom_interval/custom_interval";
import DateList from "../../shared/date_list";
import DateRange from "../../shared/date_range/date_range";
import DeleteConfirmation from "../../shared/delete_confirmation/delete_confirmation";
import InfoBlock from "../../shared/info_block/info_block";
import {getOptionsSlotInterval} from "../../shared/radio_buttons/options_slot_interval";
import TimeRange from "../../shared/time_range/time_range";
import {clearValidateAction} from "../employee_availability_actions";
import {selectAvailabilityAddedSlotId, selectDeletedSlotIds, selectStatus, selectValidate} from "../employee_availability_selectors";
import ConflictList from "./conflict_list";
import useStyles from "./employee_availability_form.styles";

const MAX_ENDTIME = (24 * 60 - 1) / 60;

/**
 *
 * @param {object} props
 * @param {AvailabilityDefaultValues} props.defaultValues
 * @param {String} props.empId
 * @param {Boolean} props.isPending
 * @param {AvailabilitySlot} [props.slot]
 * @param {Function} props.onChange
 * @param {Function} props.onDelete
 * @param {Function} props.onSave
 * @param {Variant} props.variant
 * @param {Number} [props.newSlotIndex]
 * @return {React.ReactElement}
 */
const EmployeeAvailabilityForm = ({defaultValues, empId, isPending, slot, onChange, onDelete, onSave, variant, newSlotIndex}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isGranted} = useSecurity();
    const {now, fromISO, endOf, diffDT, startOf, format, areSame, plusDT} = useContext(DateContext);

    const isOnlyAllowedOwn = !isGranted(PERMISSION.MODIFY_PRACTITIONER) && isGranted(PERMISSION.MODIFY_PRACTITIONER_OWN);

    const startTimeOfTheDay = useSelector(selectThemeStartTimeOfTheDay);
    const availabilityMode = useSelector(selectAvailabilityMode);
    const validate = useSelector((state) => selectValidate(state, {slotId: slot?._id || newSlotIndex}));
    const addedSlotId = useSelector((state) => selectAvailabilityAddedSlotId(state, {newSlotIndex}));
    const deletedSlotIds = useSelector(selectDeletedSlotIds);
    const userPractitionerId = useSelector(selectCurrentUserPractitionerId);
    const status = useSelector(selectStatus);

    const dates = {
        start: now(),
        end: now()
    };
    if (defaultValues && defaultValues.start && defaultValues.end) {
        dates.start = defaultValues.start;
        dates.end = defaultValues.end;
    }
    if (slot?.startDate && slot?.endDate) {
        dates.start = fromISO(slot.startDate);
        dates.end = fromISO(slot.endDate);
    }

    const [isValid, setValid] = useState(true);
    const [period, setPeriod] = useState(dates);
    const [time, setTime] = useState({
        start: slot?.startDate
            ? setLocalHourToDateTime(fromISO(slot.startDate), slot.hourStartLocalTime)
            : setLocalHourToDateTime(now(), startTimeOfTheDay || config.DEFAULT_START_TIME),
        end: slot?.endDate
            ? setLocalHourToDateTime(fromISO(slot.endDate), slot.hourEndLocalTime)
            : setLocalHourToDateTime(now(), config.DEFAULT_END_TIME)
    });

    const [employee, setEmployee] = useState(empId || isOnlyAllowedOwn ? userPractitionerId : "");
    const [interval, setInterval] = useState(slot?.interval || defaultValues?.interval || "user-defined");
    const [customNumber, setCustomNumber] = useState(slot?.repeatAfterDays ? slot.repeatAfterDays / 7 : 1);
    /** @type [Array<Number>, Function] */
    const [customRepeat, setCustomRepeat] = useState(slot?.frequency || []);
    const [allDay, setAllDay] = useState(slot?.hourStartLocalTime === 0 && slot?.hourEndLocalTime > MAX_ENDTIME ? true : false);

    useEffect(() => {
        if (isResolved(status) && isValid) {
            onChange(getData());
        }
    }, [status, isValid]);

    const validateFunc = () => {
        if (!period.start.isValid || !period.end.isValid || !time.start.isValid || !time.end.isValid) {
            return false;
        }

        if (period.start > endOf(period.end, "day")) {
            return false;
        }
        const endHour = convertHourMinuteDateTimeToHours(time.end);
        const startHour = convertHourMinuteDateTimeToHours(time.start);
        if (endHour <= startHour) {
            return false;
        }
        if (interval === "user-defined" && diffDT(period.end, period.start, "days") < customNumber * 7 - 1) {
            return false;
        }

        if (interval === "user-defined" && customRepeat.length === 0) {
            return false;
        }
        return Boolean(employee);
    };

    useEffect(() => {
        if (empId) {
            setEmployee(empId);
        }
    }, [empId]);

    useEffect(() => {
        setValid(validateFunc());
    }, [period, time, employee, interval, customNumber, customRepeat]);

    const getData = () => ({
        type:
            variant === config.AVAILABILITY_VARIANTS.AVAILABILITY
                ? config.AVAILABILITY_VARIANTS.AVAILABILITY
                : config.AVAILABILITY_VARIANTS.ABSENCE,
        startDate: format(startOf(period.start, "day"), DATE_FORMATS.ISO_DATE),
        endDate: format(endOf(period.end, "day"), DATE_FORMATS.ISO_DATE),
        hourStartLocalTime: allDay ? 0 : convertHourMinuteDateTimeToHours(time.start),
        hourEndLocalTime: allDay ? 23.99 : convertHourMinuteDateTimeToHours(time.end),
        interval,
        repeatAfterDays: interval === "user-defined" ? customNumber * 7 : undefined,
        frequency: interval === "user-defined" ? customRepeat.filter((item) => item !== null) : undefined,
        employee,
        id: slot?._id || addedSlotId || undefined,
        newSlotIndex: newSlotIndex
    });

    useEffect(() => {
        if (validateFunc()) {
            onChange(getData());
        } else {
            // clear validation
            dispatch(clearValidateAction(slot?._id || newSlotIndex));
        }
    }, [isValid, interval, period, time, employee, customRepeat, allDay]);

    const handleChangePeriod = (date, name) => {
        if (name === "start" && interval === "none" && date.isValid) {
            setPeriod({
                start: date,
                end: date
            });
        } else {
            setPeriod((prevState) => ({
                ...prevState,
                [name]: date
            }));
        }
    };
    const handleChangeTime = (date, name) => {
        setTime((prevState) => ({
            ...prevState,
            [name]: date
        }));
    };

    const handleChangeInterval = ({target}) => {
        // reset customRepeat and customNumber
        if (interval === "user-defined" && target.value !== "user-defined") {
            setCustomRepeat([]);
            setCustomNumber(1);
        }
        // If "einmalig" is selected and end date is not as same as start date, set start date on end date
        if (target.value === "none" && period.end && !areSame(period.start, period.end, "day")) {
            setPeriod({
                start: period.start,
                end: period.start
            });
        }

        // If !custom to custom and a duration is less than a week , set endDate = startDate + 6 days
        // If the start date is 2022-07-04 and the end date is 2022-07-10, diff is 6, so less than 6 is considered less than a week
        if (interval !== "user-defined" && target.value === "user-defined" && diffDT(period.end, period.start, "days") < 6) {
            setPeriod({
                ...period,
                end: plusDT(period.start, "day", 6)
            });
        }

        setInterval(target.value);
    };

    const handleDelete = () => {
        // Set employee (id) from state
        const mergedSlot = cloneDeep(slot);

        /** @type {AvailabilitySlot} */
        if (!mergedSlot.employee) {
            mergedSlot.employee = employee;
        }
        onDelete(
            mergedSlot,
            addedSlotId,
            variant === config.AVAILABILITY_VARIANTS.AVAILABILITY
                ? config.AVAILABILITY_VARIANTS.AVAILABILITY
                : config.AVAILABILITY_VARIANTS.ABSENCE
        );
    };

    const handleSave = () => {
        if (!isValid || isPending) {
            return;
        }

        onSave(employee, getData());
    };
    const toggleAllDay = () => {
        setAllDay(!allDay);
    };

    const isNew = !slot?._id && !addedSlotId;

    const dateRangeLabels = {
        start: ["user-defined", "daily"].includes(interval) ? t("EmployeeAvailabilityForm.startDate") : t("EmployeeAvailabilityForm.date"),
        end: t("EmployeeAvailabilityForm.endDate")
    };
    const dateRangeDisabled = {
        start: false,
        end: interval === "none"
    };
    const availabilityDisabled =
        variant === config.AVAILABILITY_VARIANTS.AVAILABILITY && config.DISABLE_AVAILABILITY_MODES.includes(availabilityMode);
    if (availabilityDisabled) {
        dateRangeDisabled.start = true;
        dateRangeDisabled.end = true;
    }
    const endHour = convertHourMinuteDateTimeToHours(time.end);
    const startHour = convertHourMinuteDateTimeToHours(time.start);

    const startEndError = endHour <= startHour ? <div>{t("App.startEndError")}</div> : null;

    let isOverlapped = null;
    const conflictsFiltered = [];
    if (validate && validate.conflicts) {
        for (const conflict of validate.conflicts) {
            if (conflict.type === config.AVAILABILITY_VARIANTS.AVAILABILITY && variant === config.AVAILABILITY_VARIANTS.AVAILABILITY) {
                isOverlapped = config.AVAILABILITY_VARIANTS.AVAILABILITY;
                conflictsFiltered.push({type: conflict.type, start: conflict.existingSlot.start, end: conflict.existingSlot.end});
            }
            if (conflict.type === config.AVAILABILITY_VARIANTS.ABSENCE && variant === config.AVAILABILITY_VARIANTS.ABSENCE) {
                isOverlapped = config.AVAILABILITY_VARIANTS.ABSENCE;
                conflictsFiltered.push({type: conflict.type, start: conflict.existingSlot.start, end: conflict.existingSlot.end});
            }
        }
    }
    const conflictError =
        isOverlapped === "absence" ? (
            <Fragment>
                <div>{t("App.conflictAbsenceError")}</div>
                <div>{t("App.conflictError")}</div>
            </Fragment>
        ) : isOverlapped === "availability" ? (
            <Fragment>
                <div>{t("App.conflictAvailabilityError")}</div>
                <div>{t("App.conflictError")}</div>
            </Fragment>
        ) : null;

    // The customNumber represents the number of weeks for the cyle
    // The duration from the period.start to the period.end should be at least equal or more than one cycle
    // example: customNumber is 2, start date is 2022-07-04, then end date must be equal or later than 2022-07-17
    const invalidWeeks = interval === "user-defined" && diffDT(period.end, period.start, "days") < customNumber * 7 - 1;

    const noRepeatError = interval === "user-defined" && customRepeat.length === 0 && <div>{t("App.missingCustomRepeat")}</div>;

    return (
        <form
            className={cx(classes.root, {
                [classes.hide]: deletedSlotIds.includes(addedSlotId)
            })}
        >
            <div className={classes.block}>
                <strong>{t("EmployeeAvailabilityForm.block")}</strong>
                <DeleteConfirmation
                    disabled={isNew || availabilityDisabled || !employee}
                    trigger={{
                        color: "primary",
                        label: t("EmployeeAvailabilityForm.delete")
                    }}
                    onConfirm={handleDelete}
                >
                    {t("EmployeeAvailabilityForm.delete_confirmation")}
                </DeleteConfirmation>
            </div>
            {availabilityDisabled && <div className={classes.info}>{t("EmployeeAvailabilityForm.fromHealthcareService")}</div>}
            <div className={classes.error}>
                {startEndError}
                {conflictError}
                {noRepeatError}
            </div>
            <div className={classes.formWrapper}>
                <div className={classes.formSection}>
                    <div className={classes.formGroup}>
                        <div className={classes.formControl}>
                            <EmployeeSelectContainer
                                className={classes.fullWidth}
                                disabled={Boolean(empId) || availabilityDisabled || isOnlyAllowedOwn}
                                label={t("EmployeeAvailabilityForm.employee")}
                                placeholder={t("EmployeeAvailabilityForm.employee_placeholder")}
                                value={employee || ""}
                                onChange={setEmployee}
                            />
                        </div>
                    </div>
                    <div className={classes.formGroup}>
                        <div className={classes.formControl}>
                            <DateRange
                                disabled={dateRangeDisabled}
                                min={startOf(fromISO(config.MIN_SLOT_DATE), "day")}
                                styles={{inputDate: classes.inputDate, marginBetween: classes.marginBetween}}
                                title={dateRangeLabels}
                                values={period}
                                onChange={handleChangePeriod}
                            />
                        </div>
                    </div>
                    <div className={classes.formGroup}>
                        <div className={classes.formControl}>
                            <TimeRange
                                disabled={allDay || availabilityDisabled}
                                styles={{inputDate: classes.inputDate, marginBetween: classes.marginBetween}}
                                title={t("EmployeeAvailabilityForm.time")}
                                values={time}
                                onChange={handleChangeTime}
                            />
                        </div>
                    </div>
                    <div className={classes.formGrop}>
                        <div
                            className={cx(classes.switch, {
                                [classes.switchSelected]: allDay
                            })}
                        >
                            <FormControlLabel
                                classes={{
                                    label: cx(classes.switchText, {
                                        [classes.switchTextSelected]: allDay
                                    })
                                }}
                                control={<Switch checked={allDay} color="primary" size="small" value={allDay} onChange={toggleAllDay} />}
                                disabled={availabilityDisabled}
                                label={t("EmployeeAvailabilityForm.allDay")}
                                labelPlacement="end"
                            />
                        </div>
                    </div>
                    <div className={classes.formGroup}>
                        <div className={classes.formControl}>
                            <CommonSelector
                                disabled={availabilityDisabled}
                                disableReset
                                fullWidth
                                items={getOptionsSlotInterval().map((option) => ({label: t(option.label), value: option.value}))}
                                styles={{input: classes.fullWidth}}
                                title={t("EmployeeAvailabilityForm.interval")}
                                value={interval}
                                onChange={handleChangeInterval}
                            />
                        </div>
                    </div>
                    {interval === "user-defined" && (
                        <div className={classes.formGroup}>
                            <div className={classes.formControl}>
                                <CustomInterval
                                    disabled={isPending || availabilityDisabled}
                                    error={invalidWeeks ? t("EmployeeAvailabilityForm.invalidWeeks") : null}
                                    number={customNumber}
                                    repeat={customRepeat}
                                    showWeekend
                                    startDate={period.start}
                                    onChangeNumber={setCustomNumber}
                                    onChangeRepeat={setCustomRepeat}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.thinDivider} />
                <div className={classes.blockSection}>
                    <InfoBlock title={t("EmployeeAvailabilityForm.nextDates")}>
                        {validate && validate.preCalcDates ? <DateList list={validate.preCalcDates} /> : t("App.not_available_short")}
                    </InfoBlock>
                </div>
                <div className={classes.thinDivider} />
                <div className={classes.blockSection}>
                    <InfoBlock title={t("EmployeeAvailabilityForm.conflicts", {count: conflictsFiltered.length})}>
                        <ConflictList list={conflictsFiltered} />
                    </InfoBlock>
                </div>
                <div className={classes.thinDivider} />
                <div className={classes.actions}>
                    <Button
                        className={classes.button}
                        color="primary"
                        data-testid="saveButtonForm"
                        disabled={Boolean(!isValid || isOverlapped || isPending || availabilityDisabled)}
                        size="small"
                        variant="contained"
                        onClick={handleSave}
                    >
                        {t("EmployeeAvailabilityForm.save")}
                    </Button>
                </div>
            </div>
        </form>
    );
};

EmployeeAvailabilityForm.propTypes = {
    defaultValues: PropTypes.shape({
        start: PropTypes.object, // DateTime object
        end: PropTypes.object, // DateTime object
        interval: PropTypes.string
    }),
    empId: PropTypes.string,
    isPending: PropTypes.bool,
    slot: PropTypes.shape({
        _id: PropTypes.string,
        type: PropTypes.oneOf([config.AVAILABILITY_VARIANTS.AVAILABILITY, config.AVAILABILITY_VARIANTS.ABSENCE]),
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        hourStartLocalTime: PropTypes.number,
        hourEndLocalTime: PropTypes.number,
        repeatAfterDays: PropTypes.number,
        frequency: PropTypes.array,
        interval: PropTypes.string
    }),
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    variant: PropTypes.string,
    newSlotIndex: PropTypes.number
};

export default EmployeeAvailabilityForm;
