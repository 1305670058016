/**
 * @fileoverview API for employee availabilities service
 */

import axiosClient from "../../middleware/axios.middelware";

const URL = "/orchestrator/pracroles/practitioners/availability";
const URL_SETTING = "/orchestrator/organization";

/**
 * fetch availabilities and absences of employees
 * @param {number} organizationId
 * @param {String} fromDate
 * @param {String} toDate
 * @return {Promise}
 */
function fetchAllEmployeesAvailabilities(organizationId, fromDate, toDate) {
    const params = {
        organizationId,
        startDate: fromDate,
        endDate: toDate
    };

    return axiosClient.get(URL, {params});
}
/**
 * fetch availability setting
 * @param {number} organizationId
 * @return {Promise}
 */
function fetchSettingAPI(organizationId) {
    return axiosClient.get(`${URL_SETTING}/${organizationId}/settings/backend.scheduler`);
}

export {fetchAllEmployeesAvailabilities, fetchSettingAPI};
